






























import { Mixins, Component } from "vue-property-decorator";
import AppMixin from "@/mixins/App";

import Article from "@/models/Article";
import Articles from "@/collections/Articles";

import Slider from "@/modules/home/components/Slider.vue";
import SectionContent from "@/components/common/SectionContent.vue";
import ServicesBox from "@/modules/services/components/ServicesBox.vue";
import BrandsCarousel from "@/modules/home/components/BrandsCarousel.vue";

@Component({
  components: {
    Slider,
    SectionContent,
    ServicesBox,
    BrandsCarousel
  }
})
export default class Home extends Mixins(AppMixin) {
  articleAbout: Article | null | undefined = null;

  mounted() {
    this.loadAbout();
  }

  async loadAbout() {
    const obCategory = this.category("about-us");
    if (!obCategory) {
      return;
    }

    const obArticles = new Articles().category(obCategory.id).limit(1);
    await obArticles.fetch();

    if (obArticles.length) {
      this.articleAbout = obArticles.first();
    }
  }
}
