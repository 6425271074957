





















































import Articles from "@/collections/Articles";
import { AppModule } from "@/store/App";
import Swiper, { SwiperOptions } from "swiper";
import { Vue, Component, Ref } from "vue-property-decorator";
import Article from "@/models/Article";

@Component
export default class Slider extends Vue {
  obArticles: Articles = new Articles();
  obThumbsSwiperOptions: SwiperOptions = {
    spaceBetween: 0,
    autoplay: {
      delay: 9500,
      disableOnInteraction: false
    },
    loop: true,
    direction: "vertical",
    loopedSlides: 1
  };
  obMainSwiperOptions: SwiperOptions = {
    spaceBetween: 10,
    centeredSlides: true,
    slidesPerView: 1,
    touchRatio: 0.2,
    slideToClickedSlide: true,
    loop: true,
    navigation: {
      nextEl: ".controls .button-next",
      prevEl: ".controls .button-prev"
    },
    pagination: {
      el: ".controls .swiper-pagination",
      type: "fraction"
    }
  };

  @Ref("obMainSlider") readonly refMainSwiper!: Swiper;
  @Ref("obThumbsSlider") readonly refThumbsSwiper!: Swiper;

  mounted() {
    this.load();
  }

  slideStyle(obArticle: Article) {
    return {
      "background-image": `url("${obArticle.preview_image}")`
    };
  }

  async load() {
    if (!this.category) {
      return;
    }

    await this.obArticles.category(this.category.id).fetch();
    this.$_.delay(this.initSlider, 300);
  }

  initSlider() {
    if (this.refMainSwiper && this.refThumbsSwiper) {
      // @ts-ignore
      const obThumbsSwiper = this.refThumbsSwiper.$swiper;
      // @ts-ignore
      const obMainSwiper = this.refMainSwiper.$swiper;

      obThumbsSwiper.controller.control = obMainSwiper;
      //   obThumbsSwiper.thumbs.swiper = obMainSwiper;
      obMainSwiper.controller.control = obThumbsSwiper;
    }
  }

  get category() {
    return AppModule.categoryCollection.filter({ slug: "portfolio" }).first();
  }
}
